import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import Container from "./Container"

const PrivateRoute = ({ component: Component, skipPadding, path, title, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      const params = new URLSearchParams(window.location.search)
      const Token = params.get('Token')
      const FullName = params.get('FullName')
      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname + `?Token=${Token}&FullName=${FullName}`}
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => (
    <Container skipPadding={skipPadding} title={title}>
      {/* <Eula user={user} onUpdate={u => setUser(u)}/> */}
      <Component {...props} />
    </Container>
  );

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;