import React from 'react';
import moment from 'moment';
import { useUserInfo } from "../../Context/UserContext";

function CustomerActions({
    monthsLeft,
    filteredInfo,
    vopayToken,
    amount,
    onChange,
    oneTimePayment,
    vopayLogin,
    setUpPAD,
}) {
    const context = useUserInfo();
    return (
        <div className="customerActions">
            {/* Car Info */}
            <div className="carInfo">
                <div className="title">
                    <h1>{filteredInfo.year + ' ' + filteredInfo.make + ' ' + filteredInfo.model}</h1>
                </div>
                <hr />
                <div className="image">
                    <img src={filteredInfo.image} alt=""
                        style={{ opacity: filteredInfo.active === false ? "0.5" : "1.0", backgroundColor: 'black' }}
                    ></img>
                </div>
                <div className="contentInfo">
                    {/* <p>License <b>GHG 5GH</b></p>*/}
                    <p>VIN <b>{filteredInfo.vin}</b></p>
                    <p>Months Left <b>{Math.round(moment(filteredInfo.end_date).diff(moment(), 'months', true))}</b></p>
                    <p>Effective date <b>{moment(filteredInfo.start_date).format("DD.MM.YYYY")}</b></p>
                    <p>Maturity date <b>{moment(filteredInfo.end_date).format("DD.MM.YYYY")}</b></p>
                    {/* <p>Current buyout <b>${Math.round((filteredInfo.prepayment_tax * ((filteredInfo.months - monthsLeft)/filteredInfo.months)) + ( filteredInfo.principle_remaining + (filteredInfo.carmodity_fee - ((((filteredInfo.months - monthsLeft))/filteredInfo.months) * (filteredInfo.carmodity_fee)))*1.12))}</b></p> */}
                    <p>Monthly payments <b>${filteredInfo.monthly_payment}</b></p>
                    <p>Amount Overdue <b>${filteredInfo.amount_overdue}</b></p>
                    <p>Lease Status <b>{filteredInfo.leased_status}</b></p>
                    <p>Payment Date <b>{filteredInfo.payment_date }</b></p>
                    <p>PAD set up <b>{filteredInfo.pad.toString() }</b></p>
                </div>
            </div>

            {/* Wallet */}
            {filteredInfo.leased_status == 'Active' && vopayToken == null && <><div className="walletCard">
                <div className="title">
                    <h1>Wallet</h1>
                </div>
                <div className="walletContent">
                    <div className="desc">
                        This is the wallet that we will take money out of on the {filteredInfo.payment_date} of every month to pay for your lease
                    </div>
                    <div className="money">
                        ${context && context.userInfo && context.userInfo.user && (context.userInfo.user.payouts_owing / 100).toFixed(2)}
                    </div>
                    {/* <div className="topUp">
                        <input placeholder="Amount" onChange={onChange()} value={amount} ></input>
                        <button onClick={oneTimePayment}>Top Up</button>
                    </div> */}
                </div>
            </div>

                {/* Payments Setup */}
                {!filteredInfo.pad && <div className="paymentsSetup">
                    <div className="title">
                        <h1>Payments Setup</h1>
                    </div>
                    <div className="paymentsContent">
                        <div className="desc">
                            Login to Vopay to set up monthly payments or make a one time payment:
                        </div>
                        <div className="setUp">
                            <button onClick={vopayLogin}>Login</button>
                        </div>
                    </div>
                </div>}</>}

            {filteredInfo.leased_status == 'Active' && vopayToken != null && <><div className="walletCard">
                <div className="title">
                    <h1>Wallet</h1>
                </div>
                <div className="walletContent">
                    <div className="desc">
                        Add money to your Wallet so you can prepay for your lease.
                    </div>
                    <div className="money">
                        ${context && context.userInfo && context.userInfo.user && (context.userInfo.user.payouts_owing / 100).toFixed(2)}
                    </div>
                    <div className="topUp">
                        <input placeholder="Amount" onChange={onChange()} value={amount}></input>
                        <button onClick={oneTimePayment}>Top Up</button>
                    </div>
                </div>
            </div>


                {/* Payments Setup */}
                {!filteredInfo.pad && <div className="paymentsSetup">
                    <div className="title">
                        <h1>Payments Setup</h1>
                    </div>
                    <div className="paymentsContent">
                        <div className="desc">
                            Click here to set up pre authorized debit payments every month on {filteredInfo.payment_date} for ${filteredInfo.monthly_payment} :
                        </div>
                        <div className="setUp">
                            <button onClick={setUpPAD}>Set up payments</button>
                        </div>
                    </div>
                </div>}</>
            }
            {/* Your files */}
            {context.userInfo && context.userInfo.links && context.userInfo.links[0] && <div className="yourFiles">
                <div className="title">
                    <h1>Your files</h1>
                </div>
                <div className="buttons">
                    <a style={{ width: '100%' }} href={context.userInfo.links[filteredInfo.lease_id].Lease_Agreement}>
                        <button style={{ width: '100%' }} className="contract">
                            Contract
                        </button></a>
                    <a style={{ width: '100%' }} href={context.userInfo.links[filteredInfo.lease_id].LOA}>
                        <button style={{ width: '100%' }} className="LOA" >
                            LOA
                        </button>
                    </a>
                    <a style={{ width: '100%' }} href={context.userInfo.links[filteredInfo.lease_id].Bill_Of_Sale}>
                        <button style={{ width: '100%' }} className="billOfSale">
                            Bill of Sale
                        </button>
                    </a>
                    <a style={{ width: '100%' }} href={context.userInfo.links[filteredInfo.lease_id].Warranty}>
                        <button style={{ width: '100%' }} className="warranty" >
                            Warranty
                        </button>
                    </a>
                    <a style={{ width: '100%' }} href={context.userInfo.links[filteredInfo.lease_id].Carfax}>
                        <button style={{ width: '100%' }} className="carFax" >
                            CarFax
                        </button>
                    </a>
                </div>
            </div>}

        </div>
    );
}

export default CustomerActions;
