import React from "react";
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import {useTheme } from '@material-ui/core/styles';
import ActiveCoreTab from "./ActiveCoreTab/ActiveCoreTab";
//import Sidebar from './Sidebar';
import '../components/style/container.css'
import '../components/style/general.css'
import { useAuth0 } from "../react-auth0-spa";

//Importing images
import carmodityLogo from '../assets/carmodityLogo.png';
import arrowTab from '../assets/arrowTab.png';
import './ActiveCoreTab/ActiveCoreTab.scss';

function Container(props) {
  const { isAuthenticated } = useAuth0();
  const { container, children, title} = props;
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div>
      <CssBaseline />
      {isAuthenticated && 
      <AppBar className="AppBar">
        <Toolbar className="dontgrow">
          <div className="clickArrow">
            <button
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className="burger">
                  <img src={arrowTab} alt=""></img>
              </button>
          </div>
          <div className="webTitle">
              <Link to="/">
                <img src={carmodityLogo} alt=""></img>
              </Link>             
          </div>
        </Toolbar>
      </AppBar>
}
{isAuthenticated &&
      <nav  className="drawer" aria-label="mailbox folders" >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" >
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            <ActiveCoreTab />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer variant="permanent" open>
            <ActiveCoreTab />
          </Drawer>
        </Hidden>
      </nav>
}
      <main className="content">
        {children}
      </main>
    </div>
  );
};

export default Container;