import React, { useState, useEffect } from 'react';
import './ActiveCoreTab.scss';
import moment from 'moment'
import { Link, useLocation } from "react-router-dom";
import carmodityLogo from '../../assets/carmodityLogo.png';
import { useUserInfo } from "../../Context/UserContext";

function ActiveCoreTab() {
    const context = useUserInfo()
    const location = useLocation();
    const activePath = location.pathname.split('/')[1];
    const [Info, setInfo] = useState();
    const defActive = () => (activePath === '') ? ('active') : ('')
    const active = (tabName) => (tabName === location.pathname.split('/')[1]) ? ('active') : ('')
    useEffect(() => {
        context.userInfo && context.userInfo.user && setInfo(context.userInfo.user);
    }, [context.userInfo])

    return (
        <div className="activeCoreTab">
            <div className="topTab">
                <div className="webTitle">
                    <Link to="/">
                        <img src={carmodityLogo} alt=""></img>
                    </Link>
                </div>
            </div>
            <div className="contentTab">
                <div className="midTab">
                    {/* User Tab */}
                    <div className="userTab">
                        {/* <div className="userImage">
                            {context.userInfo && <img className='photo' src={`https://www.tinygraphs.com/spaceinvaders/${context.userInfo.user_id}?theme=base&numcolors=4&size=220&fmt=svg`} />}
                        </div> */}
                        <div className="userDescription">
                          { Info? <> <div className="userName">{Info.first_name+" "+Info.last_name}</div>
                            <div className="email">{Info.email}</div></>:
                            <> <div className="userName">user name</div>
                            <div className="email">user email</div></>
                            }
                        </div>
                    </div>

                    {/* Tab Options */}      
                    <div className="tabOptions">
                        <div className={`${active('mylease')} ${defActive()}`}>
                            <Link to="/mylease">
                                <button>
                                    <div className="tabTitle">My Lease</div>
                                </button>
                            </Link>
                        </div>
                    </div>              
                </div>

                <div className="bottomTab">
                    <div className="dateInfo">
                        <p>{(moment().format(  "h:mm:ss A"))}</p>
                        <p>{(moment().format(  "MMMM DD, YYYY"))}</p>
                    </div>
                    {context.logout &&
                    <div className="logoutButton">
                        <button onClick={() => context.logout()}>LOG OUT</button>
                    </div>}
                </div>

            </div>
        </div>
    )
}

export default ActiveCoreTab
