import React from 'react';
import moment from 'moment';
import { useUserInfo } from "../../Context/UserContext";

function CustomerInfo({
    selectedValue,
    handleChange,
    setFirstName,
    editing,
    first_name,
    last_name,
    setLastName,
    phone,
    setPhone,
    postal_code,
    setPostalCode,
    changeUserInfo,
    startEditing,
    filteredInfo,
    selectedl,
}) {
  
    const context = useUserInfo();
    return(
    <div className="customerInfo">
     <div className="customerAsset">
        <div className="title">
            <h1>Your Assets</h1>
        </div>
        <div className="contentInfo">
            {context.userInfo.lessees.map((row, i) => (
                <button
                    style={{ backgroundColor: i == selectedValue ? "white" : "#eeeeee" }}
                    type="radio"
                    checked={i == selectedValue}
                    value={i}
                    onClick={handleChange}
                    className={`assetCard`}>
                    <div className="carName">
                        {row.year + " " + row.make + " " + row.model}
                    </div>
                    <div className="status">
                        {row.active === true ? "Active" : 'Inactive'}
                    </div>
                </button>))}
        </div>
    </div>
    {/*the contacts */}
    <div className="contactInfo">
        <div className="title">
            <h1>Your contact information</h1>
        </div>
        <div className="inputs">
            <input
                placeholder={context.userInfo.user.first_name == 'NaN' ? 'First Name' : context.userInfo.user.first_name}
                disabled={!editing}
                onChange={(e) => setFirstName(e.target.value)}
                value={first_name}>
            </input>
            <input
                placeholder={context.userInfo.user.last_name == 'NaN' ? 'Last Name' : context.userInfo.user.last_name}
                disabled={!editing}
                onChange={(e) => setLastName(e.target.value)}
                value={last_name}>
            </input>
            <input
                placeholder={context.userInfo.user.phone == 'NaN' ? 'Last Name' : context.userInfo.user.phone}
                disabled={!editing}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}>
            </input>
            <input
                placeholder={context.userInfo.user.postal_code == 'NaN' ? 'Postal Code' : context.userInfo.user.postal_code}
                disabled={!editing}
                onChange={(e) => setPostalCode(e.target.value)}
                value={postal_code}
            >
            </input>
        </div>
        {editing ?
            <div className="button" onClick={changeUserInfo}>
                <button style={{backgroundColor:'#702632', color:'white'}}>SAVE</button>
            </div> :
            <div className="button" onClick={() => startEditing()} >
                <button>EDIT</button>
            </div>}
       
    </div>
    {/*transaction history */}
    <div className="transactionHistory">
        <div className="title">
            <h1>Transaction history</h1>
        </div>
        <div className="contentInfo">
            {filteredInfo.active === false ? <div className="contentCard">
                <div className="name">
                    Lease Completed
                </div>
            </div>
                : ''}
            {context.userInfo.payments.map((row1, i) => (
                row1.lease_id == selectedl ? <div className="contentCard">
                    <div className="date" style={{ color: row1.late === true ? "red" : "black" }}>
                        {moment(row1.date).format("DD/MM/YY")}
                    </div>
                    <div className="name">
                        {row1.amount / 100 > filteredInfo.monthly_payment ? filteredInfo.monthly_payment : row1.amount / 100 < filteredInfo.monthly_payment ? "Under payment received" : "Full payment received - " + (moment(row1.date).format("MM/YY"))}
                    </div>
                    <div className="price">
                        $ {row1.amount / 100}
                    </div>
                </div> : i < 1 ? "No payment history yet" : " "
            ))}
        </div>
    </div>
    {/*payment tracker */}
    <div className="paymentTracker">
        <div className="title">
            <h1>Payment Tracker</h1>
        </div>
        <div className="trackerTable">
            <table>

                <tr className="months">
                    <th className="empty"></th>
                    {context.userInfo.payments.map((row3, i) => (
                            row3.lease_id == selectedl ?
                               <th>{moment(row3.date).format("MMMM YYYY")}</th> : " "
                    ))}
                </tr>


                <tr>
                    <th className="category">Date Paid</th>


                    {context.userInfo.payments.map((row3, i) => (
                         row3.lease_id == selectedl ?
                         <th>{moment(row3.date).format("MM/YY")}</th> : " "
                    ))}


                </tr>
                <tr>
                    <th className="category">Amt</th>
                    {context.userInfo.payments.map((row3, i) => (
                            row3.lease_id == selectedl ?
                               <th>{ "$" + row3.amount / 100}</th> : " "
                        
                    ))}
                </tr>

            </table>
        </div>
        <div className="info">
            <p>See something out of the ordinary?</p>
            <p>Contact us and let us know</p>
        </div>
    </div>
</div>
  )
}

export default CustomerInfo;
