import React, { useState, useEffect } from 'react';
import './MyLease.scss';
import moment from 'moment'
import { useUserInfo, useUserInfoUpdate } from "../../Context/UserContext";
import gif from "../../assets/ZZ5H.gif";
import env from "react-dotenv";

import CustomerActions from './CustomerActions';
import CustomerInfo from './CustomerInfo';

const API = env.API_URL
function MyLease() {
    const context = useUserInfo();
    const {accessToken, userInfo, vopayToken, now, logout} = context;
    const [filteredInfo, setFilteredInfo] = useState()
    const [selectedl, setSelectedl] = useState()
    const resetTheClock = useUserInfoUpdate()
    const [amount, setAmount] = useState()
    const [selectedValue, setSelectedValue] = useState(0);
    const [editing, setEditing] = useState(false)
    const [first_name, setFirstName] = useState()
    const [last_name, setLastName] = useState()
    const [phone, setPhone] = useState()
    const [postal_code, setPostalCode] = useState()
    const [monthsLeft, setMonthsLeft] = useState()

    // useEffect(() => {
    //     window.dataLayer.push({
    //       event: 'pageview'
    //     });
    //    }, [])
    //getuserbyemail
    //getleasebyemail      kick them out if no lease
    //get history
    //get payments


    useEffect(() => {
        context.userInfo && context.userInfo.lessees && setFilteredInfo(context.userInfo.lessees[0]);
        context.userInfo && context.userInfo.lessees && setSelectedl(context.userInfo.lessees[0].lease_id);
        context.userInfo && context.userInfo.lessees && setMonthsLeft(Math.round(moment(context.userInfo.lessees[0].end_date).diff(moment(), 'months', true)))
    }, [context.vopayToken,context.userInfo])


    const startEditing = () => {
        setEditing(true)
        context.userInfo.user.first_name != 'NaN' && setFirstName(context.userInfo.user.first_name)
        context.userInfo.user.last_name != 'NaN' && setLastName(context.userInfo.user.last_name)
        context.userInfo.user.phone != 'NaN' && setPhone(context.userInfo.user.phone)
        context.userInfo.user.postal_code != 'NaN' && setPostalCode(context.userInfo.user.postal_code)
    }

    function vopayLogin() {
        fetch(`${API}/vopayLogin`,
            {
                method: 'GET',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                }
            })
            .then(response => response.json())
            .then(login => {
                window.location.replace(login.EmbedURL)
            })
    }

    const onChange = key => (e) => {
        const re = /^[0-9\b]+$/;
        let d = isNaN(e.target.value) ? 1 : e.target.value
        if (d == '' || re.test(d)) {
            setAmount(d)
        }

    }

    const setDate1 = (payment_date) => {
        console.log('setdate1')
        console.log(parseInt(payment_date) )
        let nextmonth=moment.utc().add(1, 'months').calendar()
        let month=moment.utc(nextmonth).format("MM")
        let date1=moment.utc().format("YYYY")+"-"+month+"-"+(parseInt(payment_date) > 10 ? payment_date : `${payment_date}`)
        //let p="00010000"
        //let f= parseInt(d)+ parseInt(p)
        //let date= moment(d).format("DD/MM/YYYY")
        return date1;
    }

    const setDate2 = (payment_date) => {
        console.log('setdate2')
        console.log(parseInt(payment_date) )
        let date2=moment.utc().format("YYYY")+"-"+moment.utc().format("MM")+"-"+(parseInt(payment_date) > 10 ? payment_date : `${payment_date}`)
        return date2;
    }


    const handleChange = (event) => {
        let list = context.userInfo.lessees
        let newList = list[event.currentTarget.value]
        setFilteredInfo(newList)
        setSelectedValue(event.currentTarget.value)
        setSelectedl(context.userInfo.lessees[event.currentTarget.value].lease_id)
    };

    

    function oneTimePayment() {
        fetch(`${API}/oneTimePayment`,
            {
                method: 'POST',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },
                body: JSON.stringify({ token: context.vopayToken, amount, ClientReferenceNumber: filteredInfo.user_id, name: context.name })
            })
            .then(response => response.json())
            .then(window.confirm('Payment successful'))
    }

    function setUpPAD() {
        //check if payment date has passed if true start=
        fetch(`${API}/setUpPAD`,
            {
                method: 'POST',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },//amount, start date, payments left, make model year, should be taken from user data
                body: JSON.stringify({ token:context.vopayToken, amount: filteredInfo.monthly_payment, ClientReferenceNumber: filteredInfo.user_id, 
                    start_date:(parseInt(filteredInfo.payment_date)<=parseInt(moment.utc().format("DD"))?setDate1(filteredInfo.payment_date):setDate2(filteredInfo.payment_date))
                    , payments: (Math.round(moment(filteredInfo.end_date).diff(moment.utc(), 'months', true)))
                    , year: filteredInfo.year, make: filteredInfo.make, model: filteredInfo.model, 
                    name: context.name != null ? context.name : (context.userInfo.user.first_name + ' ' + context.userInfo.user.last_name)
                })
                    
            })
            .then(response => {window.confirm('Pre authorized debit set up successful') && resetTheClock()})
            filteredInfo.payment_date==(moment.utc().format("DD"))&&
            fetch(`${API}/oneTimePayment`,
            {
                method: 'POST',
                mode: 'cors',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${context.accessToken}`
                },
                body: JSON.stringify({ token: context.vopayToken, amount:filteredInfo.monthly_payment, ClientReferenceNumber: filteredInfo.user_id })
            })
    }
    const changeUserInfo = () => {
        setEditing(false)
        fetch(`${API}/updateUser`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${context.accessToken}`
            },
            body: JSON.stringify({ first_name, last_name, phone, postal_code, user_id: context.userInfo.user.user_id })
        })
            .then(res => window.confirm('Update successful'))
            .then(itstime => resetTheClock())
    }

    const [activeSection, setActiveSection] = useState('actions');

    const updateActiveSection = (sectionName) => {
        setActiveSection(sectionName);
    }

    const active = (tabName) => {
        if (tabName == activeSection) return 'active'
        else return ''
    }


    return (
        context && context.userInfo && filteredInfo && context.userInfo !== "goof" ? <div className="myLeasePage">
            <div className="myLease">
                <div className="pageTitle">
                    <p></p>
                    <div className="pageHeaderWeb">Your Lease Information</div>
                    <div className="pageHeaderMobile">Lease info</div>
                </div>
                <div className="mobileButton">
                    <div className="divButton">
                        <button className={`${active('actions')}`} onClick={() => updateActiveSection('actions')}>Payments</button>
                        <button className={`${active('info')}`} onClick={() => updateActiveSection('info')}>Account</button>
                    </div>
                </div>
                <div className="pageContent">
                    <CustomerActions 
                                    monthsLeft={monthsLeft}
                                    filteredInfo={filteredInfo}
                                     vopayToken={vopayToken}
                                     amount={amount}
                                     onChange={onChange}
                                     oneTimePayment={oneTimePayment}
                                     vopayLogin={vopayLogin}
                                     setUpPAD={setUpPAD} />

                    {context.userInfo.lessees ?
                    <CustomerInfo  selectedValue={selectedValue}
                                    handleChange={handleChange}
                                    setFirstName={setFirstName}
                                    editing={editing}
                                    first_name={first_name}
                                    last_name={last_name}
                                    setLastName={setLastName}
                                    phone={phone}
                                    setPhone={setPhone}
                                    postal_code={postal_code}
                                    setPostalCode={setPostalCode}
                                    changeUserInfo={changeUserInfo}
                                    startEditing={startEditing}
                                    filteredInfo={filteredInfo}
                                    selectedl={selectedl} />  : null }
                </div>


                <div className="pageContentMobile">
                    {activeSection === 'actions' ?         
                        <CustomerActions 
                                        monthsLeft={monthsLeft}
                                        filteredInfo={filteredInfo}
                                        vopayToken={vopayToken}
                                        amount={amount}
                                        onChange={onChange}
                                        oneTimePayment={oneTimePayment}
                                        vopayLogin={vopayLogin}
                                        setUpPAD={setUpPAD} />
                     :
                        /*your assets*/
                        context.userInfo.lessees ?
                        <CustomerInfo  selectedValue={selectedValue}
                                        handleChange={handleChange}
                                        setFirstName={setFirstName}
                                        editing={editing}
                                        first_name={first_name}
                                        last_name={last_name}
                                        setLastName={setLastName}
                                        phone={phone}
                                        setPhone={setPhone}
                                        postal_code={postal_code}
                                        setPostalCode={setPostalCode}
                                        changeUserInfo={changeUserInfo}
                                        startEditing={startEditing}
                                        filteredInfo={filteredInfo}
                                        selectedl={selectedl} /> : null
                    }
                </div>
            </div>
        </div> : <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:'center',alignItems:"center"}}><img src={gif} width='50px' alt="" height='50px'/></div>
    )
}

export default MyLease
