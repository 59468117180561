import './App.css';
import LoginPage from './components/LoginPage/LoginPage';
import MyLease from './components/MyLease/MyLease';
import { Router, Switch, Route } from "react-router-dom";
import history from "./utils/history"
import PrivateRoute from "./layout/PrivateRoute";
import { UserContextProvider } from './Context/UserContext';

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <Router history={history}>
          <Switch>
            <PrivateRoute key="/mylease" path="/" component={MyLease} title="MyLease" />
            {/* <Route key="/loginpage" path="/" component={LoginPage} title='LoginPage' /> */}
          </Switch>
        </Router>
      </UserContextProvider>
    </div>
  );
}

export default App;
