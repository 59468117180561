import React, { useState, useContext, useEffect } from 'react'
import { useAuth0 } from '../react-auth0-spa';
import moment from 'moment'
import env from "react-dotenv";
const API = env.API_URL
const UserContext = React.createContext()
const UserUpdateContext = React.createContext()

export function useUserInfo() {
    return useContext(UserContext)
}

export function useUserInfoUpdate() {
    return useContext(UserUpdateContext)
}



export function UserContextProvider({ children }) {
    const [userInfo, setUserInfo] = useState()
    const [accessToken, setAccessToken] = useState('')
    const [clock, setClock] = useState(false)
    const [vopayToken, setVopayToken] = useState(null)
    const [name, setName] = useState(null)
    const { loading, user, getTokenSilently, logout } = useAuth0();
    const [now, setNow] = useState()

    function resetTheClock() {
        setClock(whatever => !whatever)
        ////console.log('clock has been reset')
    }

    useEffect(() => {
        if (loading || !user) {
            return;
        }
        getTokenSilently({
        }).then(response => setAccessToken(response))

        function getUserInfo() {
            //console.log(user)
            fetch(`${API}/lesseeViewLogin`,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then(response => response.json())
                .then(users => {
                    if (users.userInfo==="goof") {
                        window.alert("According to our files you do not currently have a lease with carmodity. If this is an error, please contact us and we'll get it sorted right away.")
                        window.location.replace('https://carmodity.ca')
                    }
                    else {setUserInfo(users);}
                })
        }
        accessToken && getUserInfo()
        const params = new URLSearchParams(window.location.search)
        const Token = params.get('Token')
        const FullName = params.get('FullName')
        Token != null && Token != 'null' && setVopayToken(Token)
        FullName != null && FullName != 'null' && setName(FullName)
        setInterval(() => {
            setNow(moment().format(" MMMM Do YYYY |  h:mm:ss A"))
        }, 1000)
    }// maybe doesnt need to run on gettoken silently, or access token
        //}

        , [loading, accessToken, clock])
    
    return (
        <UserContext.Provider value={{ accessToken, userInfo, vopayToken, now, logout, name}}>
            <UserUpdateContext.Provider value={resetTheClock}>
                {children}
            </UserUpdateContext.Provider>
        </UserContext.Provider>
    )
}